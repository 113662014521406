<template>
  <section id="games">
    <div class="container">
      <div class="games">
        <div class="game">
          <img src="@/assets/img/shake.png" alt="shake-game">
          <div class="game-content">
            <h2>Shake triple puzzle quest</h2>
            <p>Conquer puzzle, explore locations and challenge Your brain in this relaxing game</p>
            <a href="https://bearbob.studio/Match3D/privacy_policy">Privacy policy</a>
            <a href="https://bearbob.studio/Match3D/terms">Terms & Conditions</a>
            <div class="links">
              <a href="https://play.google.com/store/apps/details?id=com.ST_dev.ShakeandMatch" class="google-play"></a>
              <a href="https://apps.apple.com/ru/app/match-3d-and-shake/id6450681776" class="app-store"></a>
            </div>
          </div>
        </div>
        <div class="game">
          <img src="@/assets/img/aimix.png" alt="aimix-game">
          <div class="game-content">
            <h2>Ai Mix evolution fusion animal</h2>
            <p>Unleash Your Creativity with Animal Fusion: Mixing Games Redefined!</p>
            <a href="https://bearbob.studio/AI/mix_privacy_policy">Privacy policy</a>
            <a href="https://bearbob.studio/AI/mix_terms">Terms & Conditions</a>
            <div class="links">
              <a href="https://play.google.com/store/apps/details?id=com.bearbobstudio.animal.evolution.mix.pet.ai" class="google-play"></a>
              <!-- <a href="#" class="app-store"></a> ------- Сюда поставите app store -->
            </div>
          </div>
        </div>
        <div class="game">
          <img src="@/assets/img/hybrid.png" alt="hybrid-game">
          <div class="game-content">
            <h2>Hybrid ai pet: mix animal DNA</h2>
            <p>Merge genetics and creativity, form incredible fusion creatures</p>
            <a href="https://bearbob.studio/hybrid_ai_pet/privacy_policy">Privacy policy</a>
            <a href="https://bearbob.studio/hybrid_ai_pet/terms">Terms & Conditions</a>
            <div class="links">
              <a href="https://play.google.com/store/apps/details?id=com.BearBobBro.AIAnimalMIx" class="google-play"></a>
              <a href="https://apps.apple.com/ru/app/ai-mix-animals-battle-fusion/id6467700388" class="app-store"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
.games {
  padding: 50px 0;
}
.game {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  gap: 30px;
}
.game h2 {
  color: #000;
  font-family: 'Roboto Slab', serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.game-content {
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 700px;
}
.game p, .game .game-content > a {
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  //max-width: 500px;
}
.game .links {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-evenly;
}

@media (max-width: 880px) {
  .game img {
    width: 45%;
    height: auto;
  }
  .game-content {
    width: 50%;
  }
}
@media (max-width: 650px) {
  .game {
    flex-direction: column;
  }
  .game img {
    width: 80%;
    height: auto;
  }
  .game-content {
    width: 90%;
  }
}
@media (max-width: 370px) {
  .game .links {
    gap: 10px;
  }
  .game-content {
    width: 100%;
  }
}
</style>
