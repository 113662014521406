<template>
  <main>
    <header>
      <div class="container">
        <nav>
          <a href="#games">Our games</a>
          <h1>Bear Bob Studio</h1>
          <a href="#contacts">Contact</a>
        </nav>
      </div>
    </header>
  </main>
  <Games />
  <footer id="contacts">
    <div class="container">
      <div class="contacts">
        <h1>Contacts:</h1>
        <div class="contacts-inner">
          <img src="@/assets/img/mail.png" alt="mail-icon">
          <a href="mailto:info@bearbob.studio">info@bearbob.studio</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<style>
@import '@/assets/global.css';

main {
  background-image: url("@/assets/img/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 640px;
}
header {
  padding: 20px 0;
}
nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: #fff;
  gap: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 32px;
}
nav a {
  font-weight: 500;
  color: #fff;
  text-decoration-line: underline;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
nav h1 {
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 600;
}
.contacts {
  padding: 0 0 60px 40px;
}
.contacts-inner {
  align-items: center;
  display: flex;
  gap: 20px;
}
.contacts h1 {
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 10px;
}
.contacts a {
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  line-height: normal;
}

@media (max-width: 880px) {
  nav {
    flex-direction: column;
    gap: 30px;
  }
}
@media (max-width: 650px) {
  .contacts {
    padding-left: 0;
    text-align: center;
  }
  .contacts-inner {
    justify-content: center;
  }
  nav, nav h1 {
    font-size: 24px;
  }
}
</style>

<script>
import Games from '@/components/Games.vue'

export default {
  name: 'App',
  components: {
    Games
  }
}
</script>
